




























































import BaseComponent from '@/core/base.component';
import { Component, Vue } from 'vue-property-decorator';
import reservationService from '@/services/reservation.service';
import LoginDto from '@/dto/reservation/login.dto';
@Component({
  name: 'Login',
})
export default class Login extends BaseComponent {
  private loginDto = new LoginDto();

  onEnter() {
    if (this.$route.query.reservationCode) {
      this.loginDto.reservationCode = this.$route.query
        .reservationCode as string;
    } else if (sessionStorage.getItem('reservationCode')) {
      this.loginDto.reservationCode = sessionStorage.getItem('reservationCode');
    }

    reservationService.login(this.loginDto).subscribe(res => {
      if (res) {
        //정확한 정보를 입력했을때
        sessionStorage.setItem(
          'reservationCode',
          this.loginDto.reservationCode,
        );
        reservationService
          .getReservInfo(res.data.reservationCode)
          .subscribe(res => {
            if (res) {
              //예약이 있으면
              this.$router.push(
                `/reservation/${res.data[res.data.length - 1].id}`,
              );
            } else {
              //예약이 없으면
              this.$router.push('/reservation/calendar');
            }
          });
      } else {
        //핸드폰번호나 코드가 정확하지 않을때
        this.$bvToast.toast('휴대폰번호나 코드가 정확하지 않습니다', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        });
      }
    });
  }
}
